import React from "react"

export default props => (
  <svg aria-label="Wayfair" viewBox="0 0 174.2 40.9" {...props}>
    <path
      fill="#86C771"
      d="M25 16l-1.6-6c-.1-.2-.2-.4-.4-.4L17 8c-.2-.1-.4 0-.6.1l-4 4 8.4 8.4 4-4c.2-.1.3-.3.2-.5z"
    />
    <path
      fill="#F8CF1E"
      d="M0 25.1l1.6 6c.1.2.2.4.4.4L8 33c.2.1.4 0 .6-.1l4-4-8.4-8.4-4 4c-.2.1-.2.4-.2.6z"
    />
    <path
      fill="#C6D535"
      d="M8 8L2 9.6c-.2 0-.3.2-.4.4L0 16c-.1.2 0 .4.1.6l4 4 8.3-8.3-4-4C8.4 8 8.2 8 8 8z"
    />
    <path
      fill="#BA83B9"
      d="M24.8 24.4l-3.9-3.9-8.4 8.3 3.9 3.9c.2.2.4.3.7.2l5.8-1.5c.2-.1.4-.3.5-.5l1.5-5.8c.2-.2.1-.5-.1-.7z"
    />
    <path
      fill="#772B7D"
      d="M4.158 20.51l8.344-8.344 8.343 8.343-8.343 8.343z"
    />
    <g fill="#772B7D">
      <path d="M36.7 31l-5.9-17.8c-.1-.2-.2-.3-.4-.3H29c-.3 0-.6-.3-.6-.6v-2.2c0-.3.3-.6.6-.6h9c.3 0 .6.3.6.6v2.2c0 .3-.3.6-.6.6h-1.1c-.3 0-.5.3-.4.6 0 0 2.2 6.7 3 9.1.1.3.6.4.8 0 1.3-3.4 4.9-12.6 4.9-12.6.1-.2.3-.4.5-.4h2.7c.2 0 .4.2.5.4 0 0 4.4 12.1 4.6 12.6.1.4.7.3.8 0 .1-.3 3.1-9.2 3.1-9.2.1-.3-.1-.5-.4-.5h-1.3c-.3 0-.6-.3-.6-.6v-2.2c0-.3.3-.6.6-.6h8.2c.4 0 .7.4.6.7-.2.6-.5 1.5-.7 2.2-.1.2-.3.4-.5.4H62c-.2 0-.3.1-.4.3l-6 17.8c-.1.2-.3.4-.5.4h-3.2c-.2 0-.5-.2-.5-.4 0 0-4.6-12.6-4.7-13-.1-.4-.6-.4-.8 0-.2.5-5 13-5 13-.1.2-.3.4-.5.4h-2.7c-.7.1-.9 0-1-.3zm46.6-2.8c-1.2.1-1.8-.5-1.8-1.9v-9.8c0-4.3-3.6-7-7.7-7-6.3 0-8.6 3-8.8 6.3 0 .3.2.6.6.6h3.1c.3 0 .5-.2.6-.5.2-1.7 1.3-3 3.3-3 2.2 0 3.5 1.1 3.5 3.6v2.1H75c-3.9 0-6.6.5-8.4 1.6-1.8 1.1-2.7 3.2-2.7 5.6 0 1.8.6 3.3 1.9 4.4 1.2 1.2 2.7 1.7 4.6 1.7 1.1 0 2.2-.2 3.3-.7.9-.4 1.8-1 2.7-1.8.2-.2.5-.1.6.1.9 1.5 1.8 2.1 3.7 2.1 1.7 0 2.5-1.6 3.2-2.5.2-.4-.1-.9-.6-.9zM76 26.6c0 .1-.2.3-.3.4-.6.5-1.1.8-1.7 1.1-.6.3-1.2.3-1.7.3-.9 0-1.7-.3-2.3-.9-.6-.6-.9-1.4-.9-2.3 0-1.3.5-2.1 1.5-2.8 1-.6 2.5-.9 4.4-.9h1v5.1z" />
      <path d="M116.2 0c-2.5 0-4.1.8-5.3 2.3-1.2 1.5-1.9 3.2-1.9 6.2v.8c0 .3-.2.4-.4.4h-8.4c-.3 0-.6.3-.6.6v2.2c0 .3.3.6.6.6h.4c.3 0 .5.3.4.6 0 0-3.1 7.1-4.3 9.9-.1.3-.6.3-.7 0-1.3-2.9-4.3-9.9-4.3-9.9-.2-.4 0-.7.3-.7h1.4c.3 0 .6-.3.6-.6v-2.2c0-.3-.3-.6-.6-.6H82.3c-.5 0-.7.5-.5.8.3.5 1 1.8 1.2 2.2.1.1.2.3.5.3h1.7c.2 0 .3.1.4.2 0 0 7.9 17 8.2 17.5.1.1 0 .3 0 .4-.1.2-1.5 3.1-1.5 3.1-.8 1.7-1.7 3.1-3.4 3.1-1.1 0-2-.8-2.4-1.2-.2-.2-.6-.2-.8.1-.3.3-1.2 1.2-1.6 1.7-.2.2-.2.5 0 .8.9.9 2.4 2.1 4.6 2.2 2.7.1 4.9-1.3 6.2-4.2 0 0 9.4-20.7 10.6-23.6.1-.2.3-.2.4-.2h2.6c.2 0 .4.2.4.4v14.4c0 .1 0 .2-.1.3-.5.6-1.5 1.8-1.9 2.4-.3.4 0 .9.4.9h9.1c.3 0 .6-.3.6-.6v-2.1c0-.3-.3-.6-.6-.6h-1.6c-.2 0-.4-.2-.4-.4V13.4c0-.2.2-.4.4-.4h2.8c.2 0 .3-.1.5-.2.4-.5 1.2-1.6 1.7-2.2.3-.4 0-.9-.5-.9h-4.5c-.2 0-.4-.2-.4-.4V6c0-1.6.8-3.1 2.5-3.1 1.9 0 2.2 1.6 2.3 2.8 0 .3.3.6.6.6h2.7c.3 0 .5-.3.5-.6-.2-4-2.7-5.7-6.8-5.7zm52 9.6c-.3-.2-1-.2-1.6-.2-2.5 0-3.7.8-5.2 3-.2.3-.7.1-.7-.2v-1.9c0-.3-.3-.6-.6-.6H156c-.2 0-.3.1-.4.2 0 0-1.3 1.6-1.7 2.2-.3.3-.1.9.5.9h.5c.2 0 .4.2.4.4v14.4c0 .1 0 .2-.1.3-.4.5-1.4 1.8-1.9 2.4-.3.4 0 .9.4.9h8.9c.3 0 .6-.3.6-.6v-2.1c0-.3-.3-.6-.6-.6H161c-.2 0-.4-.2-.4-.4V16.9c0-.1 0-.2.1-.3 1-1.8 3.2-3.1 4.8-3.1 1.4 0 1.9.2 2.6.4.3.1.6-.3.6-.6V10c-.1-.1-.3-.2-.5-.4zm-17.3 18.6h-1.4c-.2 0-.4-.2-.4-.4V10.2c0-.3-.3-.6-.6-.6h-4.1c-.2 0-.3.1-.5.2-.2.3-1.3 1.6-1.7 2.1-.3.4 0 .9.4.9h.5c.2 0 .4.2.4.4v14.4c0 .1-.1.3-.1.3-.5.6-1.4 1.8-1.9 2.4-.3.4 0 .9.4.9h7.2c.2 0 .3-.1.4-.2.4-.5 1.3-1.6 1.7-2.1.4-.2.1-.7-.3-.7z" />
      <circle cx="146.3" cy="4.2" r="3.1" />
      <path d="M139.2 28.2c-1.2.1-1.8-.5-1.8-1.9v-9.8c0-4.3-3.6-7-7.7-7-6.3 0-8.6 3-8.8 6.3 0 .3.2.6.6.6h3.1c.3 0 .5-.2.6-.5.2-1.7 1.3-3 3.3-3 2.2 0 3.5 1.1 3.5 3.6v2.1h-1.1c-3.9 0-6.6.5-8.4 1.6-1.8 1.1-2.7 3.2-2.7 5.6 0 1.8.6 3.3 1.9 4.4 1.2 1.2 2.7 1.7 4.6 1.7 1.1 0 2.2-.2 3.3-.7.9-.4 1.8-1 2.7-1.8.2-.2.5-.1.6.1.9 1.5 1.8 2.1 3.7 2.1 1.7 0 2.5-1.6 3.2-2.5.2-.4-.1-.9-.6-.9zm-7.3-1.6c0 .1-.2.3-.3.4-.6.5-1.1.8-1.7 1.1-.6.3-1.2.3-1.7.3-.9 0-1.7-.3-2.3-.9-.6-.6-.9-1.4-.9-2.3 0-1.3.5-2.1 1.5-2.8 1-.6 2.5-.9 4.4-.9h.9v5.1zM173.3 12.8h-.3l-.5-.7h-.2v.7h-.3v-1.9h.7c.4 0 .6.3.6.6s-.1.6-.5.6l.5.7zm-1-1.7v.7h.5c.2 0 .3-.2.3-.4s-.1-.4-.3-.4h-.5z" />
      <path d="M172.6 10.4c.8 0 1.4.6 1.4 1.4 0 .8-.6 1.4-1.4 1.4s-1.4-.6-1.4-1.4c0-.7.6-1.4 1.4-1.4m0-.2c-.9 0-1.6.7-1.6 1.6s.7 1.6 1.6 1.6 1.6-.7 1.6-1.6-.7-1.6-1.6-1.6z" />
    </g>
  </svg>
)
